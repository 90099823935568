import React from 'react'
import Anchor from '../anchor'
import Heading from '../../ui/heading'
import { RiExternalLinkLine } from 'react-icons/ri'
import { compose, withProps, withStateHandlers } from 'recompose'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from 'react-google-maps'

const Map = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_MAPS_APIKEY}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `60vh` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withStateHandlers(() => ({ isOpen: false }), {
    onToggleOpen: ({ isOpen }) => (id) => ({
      isOpen: {
        [id]: isOpen[id] === undefined ? true : !isOpen[id],
      },
    }),
  }),
  withScriptjs,
  withGoogleMap,
)((props) => (
  <GoogleMap
    defaultZoom={10}
    defaultCenter={{ lat: 51.5600154, lng: 4.6943025 }}
  >
    {props.isMarkerShown && (
      <>
        {props.markerList.map(({ id, name, location, website, people }) => (
          <Marker
            key={id}
            position={{ lat: location.lat, lng: location.lon }}
            onClick={() => {
              props.onToggleOpen(id)
            }}
          >
            {props.isOpen[id] === true && (
              <InfoWindow onCloseClick={props.onToggleOpen}>
                <>
                  <Heading as="h5">{name}</Heading>

                  {people && (
                    <Heading as="h6" color="#666" fontSize="14px" mb="10px">
                      Inwonersaantal: {people}
                    </Heading>
                  )}

                  <Anchor
                    external
                    target="_blank"
                    rel="noopener"
                    path={website}
                  >
                    Bezoek site
                    <RiExternalLinkLine className="icon" role="img" />
                  </Anchor>
                </>
              </InfoWindow>
            )}
          </Marker>
        ))}
      </>
    )}
  </GoogleMap>
))

export default Map
