import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import SEO from '../../components/seo'
import Header from '../../containers/header'
import Footer from '../../containers/footer'
import Map from '../../components/ui/map'
import Heading from '../../components/ui/heading'
import Text from '../../components/ui/text'
import { Container, Row, Col } from '../../components/ui/wrapper'
import { BreadcrumbWrapper } from '../../components/hero/hero-area.style'

const IndexPage = ({ pageContext, location }) => {
  const pageData = useStaticQuery(graphql`
    query AreaPageQuery {
      site {
        siteMetadata {
          defaultTitle: title
          titleTemplate
          defaultDescription: description
          siteUrl
          defaultImage: image
        }
      }

      page: allContentfulPagina(
        filter: { id: { eq: "9e5210ec-f2ad-5ae5-9f62-de6b192162a8" } }
      ) {
        edges {
          node {
            headerTitel
            headerSubtitel
            headerButtonTekst
            headerButtonLink
            headerImage {
              file {
                url
                fileName
                contentType
              }
              fluid(maxWidth: 900) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }

      township: allContentfulGemeente {
        edges {
          node {
            id
            name
            website
            people
            location {
              lat
              lon
            }
          }
        }
      }
    }
  `)

  const metadata = pageData.site.siteMetadata
  metadata.url = `${metadata.siteUrl}/interactieve-kaart`
  const {
    headerTitel,
    headerSubtitel,
    headerImage,
  } = pageData.page.edges[0].node
  const township = pageData.township.edges.map((edge) => edge.node)
  const [flyoutOpen, setFlyoutOpen] = useState(false)
  const crumbs = pageContext
    ? pageContext.breadcrumb.crumbs
        .map((crumb) => {
          crumb.crumbLabel = crumb.crumbLabel
            .replace('-', ' ')
            .replace('rwb', 'RWB')
          crumb.crumbLabel =
            crumb.crumbLabel.charAt(0).toUpperCase() + crumb.crumbLabel.slice(1)
          return crumb
        })
        .filter((crumb, index) => {
          return index < 2
        })
    : false

  const flyoutHandler = () => {
    setFlyoutOpen((prevState) => !prevState)
  }

  return (
    <>
      <SEO
        title={`Over de regio: ${headerTitel}`}
        description={headerSubtitel || headerTitel}
        image={headerImage?.file?.url}
        metadata={metadata}
      />

      <Header
        transparent
        flyoutOpen={flyoutOpen}
        flyoutHandler={flyoutHandler}
      />
      <main id="main" className="site-wrapper-reveal">
        <Container className="container-xl" pl={0} pr={0}>
          <Row noGutter={true}>
            <Col>
              {crumbs && (
                <BreadcrumbWrapper align="center" position="relative">
                  <Container fluid="lg">
                    <Breadcrumb crumbs={crumbs} crumbSeparator="›" />
                  </Container>
                </BreadcrumbWrapper>
              )}
            </Col>
          </Row>

          <Row noGutter={true} justify="center">
            <Col xl={5} lg={6} ml="15px" mr="15px">
              <Heading as="h1" textalign="center" mb="30px" pl="15px" pr="15px">
                {headerTitel}
              </Heading>
              <Text as="div" align="center" mb="40px" pl="15px" pr="15px">
                {headerSubtitel}
              </Text>
            </Col>
          </Row>

          <Row noGutter={true} mb="100px">
            <Col>
              <Map isMarkerShown markerList={township} />
            </Col>
          </Row>
        </Container>
      </main>
      <Footer />
    </>
  )
}

export default IndexPage
